.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .scroll-button {
    background-color: #FFD700;
    color: rgb(5, 5, 5);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .scroll-button:hover {
    background-color: yellow;
    transform: scale(1.1);
  }
  
  @media (max-width: 600px) {
    .scroll-button {
      bottom: 15px;
      right: 15px;
      padding: 8px;
    }
  }
  