.Head {
  width: 100%;
  position: relative;
  margin: 0; /* Remove any default margin to eliminate white space */
  padding: 0; /* Remove padding to ensure no extra space */
}

.HeadImage {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove extra space below the image */
  object-fit: cover; /* Ensure the image covers the area */
}

.HeadBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  background-color: #f5d500;
  padding: 0 20px;
  box-sizing: border-box; /* Include padding in width calculation */
  margin: 0; /* Remove margin to ensure it sits flush against the image */
}

.news {
  font-family: "Permanent Marker", Sans-serif;
  font-weight: 400;
  font-size: 26px;
  color: #12141a;
  margin-right: 16px;
}

.subscribe {
  line-height: 1.8;
  font-size: 16px;
  color: #3f3f3f;
  font-family: muli, sans-serif;
  margin-right: 20px;
}

.emailSide {
  display: flex;
  align-items: center;
  height: 38px;
  flex-direction: row;
  flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

input[name="myInput"] {
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: muli, sans-serif;
  color: #3f3f3f;
  border: 2px solid #fff;
  width: 250px;
  margin-right: 7px;
  margin-bottom: 5px; /* Add space between input and button when wrapped */
}

.signUp, .whatsappButton {
  background-color: #666;
  font-family: "Permanent Marker", Sans-serif;
  font-size: 14px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-left: 10px;
  margin-bottom: 5px; /* Add space below button when wrapped */
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 4px;
  white-space: nowrap; /* Prevent text from wrapping within the button */
}

.errorMessage {
  line-height: 1.2;
  font-size: 10px;
  color: #a8182c;
  margin-left: 10px;
  align-self: flex-start;
  font-family: muli, sans-serif;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .HeadBody {
      flex-direction: column;
      align-items: center;
      padding: 15px;
  }

  .news, .subscribe, .emailSide {
      text-align: center;
      margin: 10px 0;
  }

  .emailSide {
      flex-direction: column;
      align-items: center;
  }

  .signUp, .whatsappButton {
      width: 100%;
      max-width: 200px;
      margin-left: 0; /* Remove side margin on smaller screens */
  }

  input[name="myInput"] {
      width: 100%;
      max-width: 300px;
  }
}

@media (max-width: 480px) {
  .news {
      font-size: 22px;
  }

  .subscribe {
      font-size: 14px;
  }

  input[name="myInput"] {
      max-width: 250px;
  }

  .signUp, .whatsappButton {
      max-width: 180px;
  }
}
