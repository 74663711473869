.menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto; /* Center the content */
}

.menu-section {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.item-heading {
  word-wrap: break-word;
  font-weight: 400;
  font-size: 28px;
  font-family: "Permanent Marker", sans-serif;
  color: #12141a;
  margin: 0;
  padding-bottom: 10px; /* Ensures consistent space below heading */
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; /* This ensures the list takes up space inside the container */
}

li {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding: 5px 0;
}

.dotted-line {
  flex-grow: 1;
  border-bottom: 1px dotted #ccc;
  margin: 0 10px;
}

.item-price {
  white-space: nowrap;
}

/* Media Query for medium screens */
@media (max-width: 900px) {
  .item-heading {
    font-size: 24px;
  }

  li {
    font-size: 16px;
    padding: 4px 0;
  }
}

/* Media Query for small screens */
@media (max-width: 600px) {
  .menu {
    padding: 10px;
    grid-template-columns: 1fr;
  }

  .menu-section {
    padding: 10px;
  }

  .item-heading {
    font-size: 22px;
  }

  li {
    font-size: 14px;
    padding: 3px 0;
  }
}
