.homeBanner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../Images/homeBackground.jpg");
  background-size: cover;
  background-position: center;
  height: 500px;
  margin-top: 16px;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
}

.homeBackground {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.homeBannerText {
  margin-bottom: 8px;
  font-family: "Permanent Marker", Sans-serif;
  font-size: 70px;
  font-weight: 400;
  color: #fff;
  line-height: 1.4;
  text-shadow: 4px 3px 1px #000000;
  position: relative;
  z-index: 10;
}

.homeBannerSubText {
  margin-bottom: 25px;
  font-size: 23px;
  text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.68);
  color: #fff;
  font-weight: 200;
  line-height: 1.4;
  font-family: "Muli", sans-serif;
}

.buttonArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.vistToday, .viewMenu {
  font-family: "Permanent Marker", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  border-radius: 30px;
  padding: 16px 40px;
  height: 48px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 10px;
  box-shadow: 0 0 0;
}

.vistToday {
  color: #3f3f3f;
  background-color: #f5d500;
}

.viewMenu {
  color: #ffffff;
  background-color: transparent;
  border: 2px solid #fff;
}

.vistToday:hover {
  background-color: black;
  color: #ffffff;
}

.viewMenu:hover {
  background-color: #ffffff;
  color: black;
}

.homeBodyWrap {
  max-width: 1200px;
  margin: 24px auto 60px;
  padding: 0 20px;
}

.homeBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 40px;
}

.textSide, .imageSide {
  flex: 1;
  padding: 40px 10px;
}

.textHeading {
  display: flex;
  align-items: center;
}

.textHeading p {
  font-size: 34px;
  line-height: 1.4em;
  font-weight: 400;
  font-family: "Permanent Marker", sans-serif;
  color: #12141a;
  margin-left: 10px;
}

.textBody p {
  margin-bottom: 28px;
  line-height: 1.8;
  font-size: 16px;
  color: #3f3f3f;
  font-family: "Muli", sans-serif;
}

.homeImage {
  width: 100%;
  height: auto;
  max-width: 480px;
  max-height: 360px;
}

.photoGallery {
  position: relative;
  height: 100px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .homeBody {
    flex-direction: column;
    align-items: center;
  }

  .textHeading p {
    font-size: 28px;
  }

  .homeBannerText {
    font-size: 50px;
  }

  .homeBannerSubText {
    font-size: 20px;
  }

  .vistToday, .viewMenu {
    width: 100%;
    max-width: 300px;
    margin: 10px 0;
  }

  .homeImage {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .homeBannerText {
    font-size: 40px;
  }

  .homeBannerSubText {
    font-size: 18px;
  }

  .vistToday, .viewMenu {
    max-width: 250px;
  }

  .homeImage {
    max-width: 100%;
  }
}
