.menubanner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../Images/menubanner.jpg");
  background-size: cover;
  background-position: center;
  height: 310px;
  margin-top: 16px;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1200px; /* Limit width to match other sections */
  margin: 0 auto; /* Center content */
  overflow: hidden;
}

.menuBannerText {
  line-height: 1;
  color: #fff;
  font-size: 36px; /* Adjusted for better readability on smaller screens */
  position: relative;
  font-family: "Permanent Marker", sans-serif;
  font-weight: 400;
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;
  text-shadow: 4px 3px 1px rgba(0, 0, 0, 0.9);
}

.menuarea {
  padding: 20px;
  max-width: 1200px; /* Match other sections */
  margin: 0 auto; /* Center content */
}
