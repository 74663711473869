/* Reset margins and padding for the entire document */
body, html {
  margin: 0;
  padding: 0;
}

.headerBody {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  background-color: #3f3f3f;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.logo {
  height: 48px;
  cursor: pointer;
  border-radius: 22px;
}

.logoDiv {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.logoText {
  font-family: "Permanent Marker", cursive;
  font-size: 40px;
  padding-left: 10px;
  color: #FFD700;
  cursor: pointer;
}

.location {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-left: auto;
}

.locationText, .slidingHoursText {
  color: #ffffff;
  font-size: 13px;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  transition: color 250ms ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locationText:hover, .slidingHoursText:hover {
  color: #FFD700;
}

.icon {
  color: #FFD700;
  height: 20px;
}

.slidingHoursContainer {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 10px;
}

.slidingHoursText {
  font-size: 13px;
  font-family: 'Muli', sans-serif;
  animation: slideDown 8s ease-in-out infinite;
  opacity: 0;
}

@keyframes slideDown {
  0% {
      transform: translateY(-100%);
      opacity: 0;
  }
  10% {
      transform: translateY(0%);
      opacity: 1;
  }
  40% {
      transform: translateY(0%);
      opacity: 1;
  }
  50% {
      transform: translateY(100%);
      opacity: 0;
  }
  100% {
      transform: translateY(100%);
      opacity: 0;
  }
}

.iconArea {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  overflow-x: auto;
  white-space: nowrap;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .headerBody {
      flex-direction: column;
      height: auto;
      padding: 10px;
  }

  .logo {
      height: 30px;
  }

  .logoText {
      font-size: 34px;
  }

  .location, .iconArea {
      width: 100%;
      margin: 5px 0;
  }

  .locationText, .slidingHoursText {
      font-size: 12px;
  }

  .icon {
      height: 20px;
  }
}

/* Extra small devices (phones, less than 576px) */
@media (max-width: 576px) {
  .iconArea .icon:nth-child(3) {
      display: none;
  }

  .locationText, .slidingHoursText {
      font-size: 11px;
  }

  .icon {
      height: 20px;
  }
}
