.menuNavBar {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 45px;
  padding: 0 10px; /* Add some padding to ensure content isn't too close to the edges */
}

.menuBarItem {
  font-family: "Permanent Marker", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(63, 63, 63, 0.81);
  background-color: rgba(245, 213, 0, 0);
  border: 2px solid #bfbfbf;
  border-radius: 36px;
  text-decoration: none;
  margin: 10px 5px; /* Adjust margins for better spacing */
  padding: 8px 16px; /* Add padding to make buttons larger and more touch-friendly */
  box-shadow: none;
  transition: background-color 0.3s ease; /* Smooth transition for hover effects */
}

.menuBarItem.menuBarItemSelected {
  background-color: #FFD700; /* Selected background color */
}

.menuBarItem:hover {
  background-color: #FFD700; /* Background color for hover menu items */
}

/* Responsive styles for tablets and below */
@media (max-width: 768px) {
  .menuBarItem {
    font-size: 13px; /* Slightly smaller font size */
    padding: 6px 12px; /* Adjust padding for smaller screens */
    margin: 8px 5px; /* Adjust margins for better spacing */
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 576px) {
  .menuBarItem {
    font-size: 12px; /* Smaller font size for small screens */
    padding: 5px 10px; /* Smaller padding for mobile */
    margin: 5px 3px; /* Reduce margins for more items in a row */
  }
}
