.locationbanner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../Images/location.jpg");
  background-size: cover;
  background-position: center;
  height: 310px;
  margin-top: 16px;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
}

.mapArea {
  filter: grayscale(100%);
  margin-bottom: 30px; /* Space between map and card list */
}

.cardList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 25px;
  justify-content: center; /* Center the cards */
}

.card {
  background: #f2f2f2;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(30% - 20px); /* 3 cards per row */
  max-width: calc(30% - 20px); /* 3 cards per row */
  box-sizing: border-box;
}

.businessName {
  margin: 0 15px 0 0;
  display: inline-block;
  color: #12141a;
  font-weight: 600;
  font-size: 14px;
  font-family: "Muli", sans-serif;
}

.landMark {
  font-size: 22px;
  margin-bottom: 8px;
  font-weight: 700;
  font-family: "Muli", sans-serif;
  color: #12141a;
}
.address{
  font-size: 18px;
  margin-bottom: 8px;
  font-family: "Muli", sans-serif;
  color: #12141a;
}

.timing {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 19px 0 0;
  font-size: 16px;
  color: #4a4949;
  font-family: "Muli", sans-serif;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .card {
    flex: 1 1 calc(45% - 20px); /* 2 cards per row on tablets */
    max-width: calc(45% - 20px);
  }
}

@media (max-width: 768px) {
  .card {
    flex: 1 1 calc(100% - 20px); /* 1 card per row on mobile */
    max-width: calc(100% - 20px);
  }

  .landMark {
    font-size: 18px;
  }

  .timing {
    font-size: 14px;
  }
}
