.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.contactbanner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
  url("../../Images/contact.jpg");
  background-size: cover;
  background-position: center;
  height: 310px;
  margin-top: 16px;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuBannerText {
  color: white;
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
}

.content {
  text-align: center;
  max-width: 800px;
  margin-top: 20px;
}

.contactNumber{
  font-size: 16px;
  line-height: 1.8;
  color: #3f3f3f;
  font-family: muli, sans-serif;
  margin-right: 8px;
}
.sectionTitle {
  font-size: 28px;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-family: "Permanent Marker", Sans-serif;
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.cateringImage {
  width: 100%;
  max-width: 480px;
  height: auto;
}

.textContainer {
  font-size: 16px;
  line-height: 1.8;
  color: #3f3f3f;
  font-family: muli, sans-serif;
}

