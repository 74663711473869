.NavBar {
  display: flex;
  width: 100%;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding-top: 12px;
}

.menu-item {
  padding: 10px 10px;
  text-decoration: none;
  color: #545454; /* Adjust default text color as needed */
  font-family: "Permanent Marker", cursive;
  font-weight: 200;
  font-size: 20px;
  transition: background-color 0.3s ease; /* Add transition effect for smoother color change */
}

.menu-itemSelected {
  background-color: #FFD700;
}

.menu-item:hover {
  background-color: #FFD700; /* Background color for hover menu items */
}
