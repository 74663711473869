/* CustomButton.css */
.custom-button {
    background-color: #82b440; /* Blue color, you can change it */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    box-shadow: 0 2px 0 #6f9a37;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: none;
  }
  
  .custom-button:hover {
    background-color: #6f9a37; /* Darker shade of blue on hover */
  }
  