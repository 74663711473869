.footerBody {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)),
    url("../../Images/background.jpg");
  background-size: cover;
  background-position: center;
  height: auto; /* Allow height to adjust based on content */
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  padding: 20px 0; /* Add padding for better spacing */
}

.textArea {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 50px 20px;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.heading {
  display: flex;
  align-items: center;
}

.about,
.hours,
.connect {
  width: 30%; /* Adjust width to be more flexible */
  min-width: 300px; /* Set a minimum width for smaller screens */
  margin-bottom: 20px; /* Add spacing between sections on smaller screens */
}

.heading p {
  margin-left: 10px;
}

.headingText {
  font-family: "Permanent Marker", Sans-serif;
  font-weight: 400;
  color: #f5d500;
  line-height: 1;
  font-size: 26px;
  display: block;
}

.aboutText {
  font-size: 16px;
  font-family: muli, sans-serif;
  color: rgba(255, 255, 255, 0.92);
  line-height: 1.8;
  font-weight: lighter;
}

.connect {
  display: flex;
  flex-direction: column;
}

.textContent > div {
  display: flex;
  align-items: center;
}

.textContent p {
  margin-left: 15px;
  line-height: 1;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.92);
  font-family: muli, sans-serif;
  font-weight: lighter;
}

.iconOnConnect {
  margin-top: 10px;
}

.openHours {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Muli", Sans-serif;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}

.devider {
  height: 0.3px;
  background-color: #625b5b;
  width: 100%;
  margin-bottom: 3px;
}

.hoursText {
  color: #f5d500;
}

.maker {
  margin-top: 40px;
}

.bodyDevider {
  margin-top: 80px;
  height: 0.3px;
  background-color: #625b5b;
  width: 100%;
  margin-bottom: 3px;
}

.copyRights {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 28px;
}

.copyRights p {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.71);
  font-family: muli, sans-serif;
  line-height: 1.8;
}

.copyRights p span {
  color: #ffffff;
}

.footerEmail {
  line-height: 1.8;
  font-size: 16px;
  color: #ffffff;
  font-family: muli, sans-serif;
  margin-left: 8px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .textArea {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .about,
  .hours,
  .connect {
    width: 80%; /* Adjust width for smaller screens */
    text-align: center;
  }

  .copyRights {
    flex-direction: column;
    text-align: center;
  }
}
